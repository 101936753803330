import React from 'react';
import Fader from './Fader';
import {Link} from 'react-router-dom';
import {Parallax} from 'svz-toolkit'
import {AnimBox} from './common';
import {
	gamerImg, 
	codeImg, 
	docImg,
	shortImg,
	computer, 
	stillCode,
	stillGamer,
	stillDoc,
	stillShort
} from '../images';
export const Tutor = (props) => {
	const {
		returnToTop, 
		isUp, 
		clicked, 
		clickedUp, 
		recommendation, 
		topBar, 
		xSmall,
		skills,
		genres,
		whoFor,
		onTutorChange,
		openModal,
		updateOrder
	} = props;
	const recommendationList = [
		{
			entry: 'code',
			title: 'Code your dream programs and apps!',
			content: 'Javascript, HTML, CSS, PHP, ReactJS, React Native, Java, C++, C#, and more.'
		},
		{
			entry: 'shortcuts',
			title: 'Master computer tricks increase your productivity!',
			content: 'AutoHotkey, Java, C#, Chrome and Firefox extensions, Bash, and more'
		},
		{
			entry: 'docs',
			title: 'Learn the ins and outs of popular programs and Office Suites!',
			content: "Microsoft Office, Google Suite, Apache OpenOffice, LibreOffice, and more."
		},
		{
			entry: 'games',
			title: 'Make your perfect web or mobile game, your own way!',
			content: "Java, C++, C#, React Native, Unity, Android Studio, PHP, and more."
		}
	]

	const recMap = recommendationList.map( (entry, index) => {
		return (
				<div key = {'recMap' + index} className = {'recEntry'.concat(recommendation[entry.entry] ? ' open' : ' closed')}>
					<div className='titleText'>
						{entry.title}
					</div>
					<div className ='contentText' id="recDiv">
						{entry.content}
					</div>
				</div>
		)
	});
	return (
		<Fader>
			<div id="tutor">
				<div className ='titleText stretch'>Tutoring Opportunities</div>
				<div>Looking for our free tutorials? <Link to="/tutorials">They're all here!</Link></div>
				<Parallax source={computer}>
					<p>You're looking for a new skill, trying to put yourself at the top of the pack</p>
					<p>There's a thousand things to learn, with only so many places to learn them.</p>
					<p>Few skills are more in demand than software design, and that demand is only growing over time.</p>
					<p>So what are you waiting for?</p>
				</Parallax>
				<Parallax src={computer} id="tutor-animbox-container">
					<div className ='titleText'><p>What do you want to make?</p></div>
					<div id='boxList' className = {topBar ? 'large' : xSmall ? 'xSmall' : 'small'}>
						<div className='animGrouper'>
							<AnimBox 
								id="animBox1"
								delay={200} 
								downClass='animBox'
								className='animBox up' 
								midClass='animBox mid'
								cover={<img alt="codeImg" src = {stillCode} />}
								isUp={isUp}
								identity='code'
								clicked={clicked}
								clickedUp={clickedUp}
							>
								<img alt="codeImg" src={codeImg} />
							</AnimBox>
							<AnimBox 
								id="animBox2"
								delay={200} 
								downClass='animBox'
								className='animBox up' 
								midClass='animBox mid'
								cover={<img alt="shortImgStill" src = {stillShort}  />}
								isUp={isUp}
								identity='shortcuts'
								clicked={clicked}
								clickedUp={clickedUp}
							>
								<img alt="shortImg" src={shortImg} />
							</AnimBox>
						</div>
						<div className='animGrouper'>
							<AnimBox 
								id="animBox3"
								delay={200} 
								downClass='animBox'
								className='animBox up' 
								midClass='animBox mid'
								cover={<img alt="docImgStill" src = {stillDoc}  />}
								isUp={isUp}
								identity='docs'
								clicked={clicked}
								clickedUp={clickedUp}
							>
								<img alt="docImg" src={docImg} />
							</AnimBox>
							<AnimBox 
								id="animBox4"
								delay={200} 
								downClass='animBox'
								className='animBox up' 
								midClass='animBox mid'
								cover={<img alt="gameImgStill" src = {stillGamer}  />}
								isUp={isUp}
								identity='games'
								clicked={clicked}
								clickedUp={clickedUp}
							>
								<img alt="gamerImg" src={gamerImg} />
							</AnimBox>
						</div>
					</div>
					<div className="tutor-summary">
						{recMap}
					</div>
					<div className="button" onClick={() => openModal('tutor', true)}>Become a Student!</div>
				</Parallax>
			</div>
		</Fader>
	)
}