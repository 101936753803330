import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {Address, Input, Button, ExpandingText, Modal, NumberMan } from 'svz-toolkit';
import './scss/Invoice.scss';

const InvoiceRow = props => {
	const {flatDiscount = 0, percentDiscount = 0, label, units, price = props.standardPrice, openEdit, currency = '$', standardized, standardPrice, numberer} = props;
	const rowTotal = units * price
	return (
		<tr onClick = { () => openEdit() } >
			<td>{label + (flatDiscount|| percentDiscount
				? "(" + ( flatDiscount
					? numberer.toDollars(flatDiscount) + (percentDiscount
						? " and " 
						: '' ) 
					: '' ) 
				+ ( percentDiscount
					? percentDiscount+ "%" 
					: ''
				) + " discount)"
				: ''
			)}</td>
			<td>{units}</td>
			{standardized ? null : <td>{currency + price}</td> }
			<td>{numberer.toDollars(rowTotal ? rowTotal*(100-percentDiscount)/100 - flatDiscount: 0)}</td>
		</tr>
	)
}

const InvoiceSection = props => {
	let standardized = props.content[0] ? Array.standardized(props.content, 'price') : null;
	standardized = standardized === null ? true : false;
	const {
		name, 
		labelsName, 
		unitsName, 
		openEdit, 
		content, 
		section, 
		exchangeRate, 
		flatDiscount,
		percentDiscount, 
		currency, 
		numberer, 
		canEdit,
		standardPrice = standardized
	} = props;
	let subTotal = 0;
	return (
		<div className="invoice-section-container">
			<table className="invoice-section-content">
				<thead className="invoice-header" onClick={() => openEdit('section')}><tr><td>{name}</td></tr></thead>
				<tbody>
					<tr onClick={() => openEdit('labels')} className="invoice-section-labels">
						<td>{ labelsName || "Item" }</td>
						<td>{ unitsName || "Units" }</td>
						{!standardized ? <td>Price</td> : null}
						<td>Line Total</td>
					</tr>
					{content.map((row, i) => {
						const {price = standardPrice, flatDiscount = 0, percentDiscount = 0} = row
						const lineTotal = price*row.units - ( price*row.units*(percentDiscount)/100 || 0) - flatDiscount
						subTotal += lineTotal;
						return <InvoiceRow 
							key={"invoice-row"+i} 
							standardPrice={standardPrice} 
							standardized={standardized} 
							openEdit = {() => openEdit(i)} 
							lineTotal = {lineTotal} 
							{...row} 
							numberer={numberer} 
						/>
					})}
				</tbody>
			</table>
			<table className="invoice-section-content">
				<tfoot>
					{canEdit ? <tr><td><Button onClick={() => openEdit(content.length)}>New Line</Button></td><td/><td/></tr> : null}
					{flatDiscount || percentDiscount
						? <tr onClick={() => openEdit('totals')}>
							<td />
							<td>Subtotal</td>
							<td>{numberer.toDollars(subTotal)}</td>
						</tr>
						: null
					}
					{percentDiscount
						? <tr onClick={() => openEdit('totals')}>
							<td />
							<td>Percent Discount</td>
							<td>{percentDiscount}%</td>
						</tr> 
						: null
					}
					{flatDiscount
						? <tr onClick={() => openEdit('totals')}>
							<td />
							<td>Flat Discount</td>
							<td>{numberer.toDollars(flatDiscount*(exchangeRate||1))}</td>
						</tr> 
						: null
					}
					<tr onClick={() => openEdit('totals')}>
						<td/>
						<td>{name} Total</td>
						<td>{numberer.toDollars( subTotal - (subTotal*(percentDiscount)/100 || 0)-(flatDiscount || 0))}</td>
					</tr>
				</tfoot>
			</table>
		</div>
	)
}

const LabelsEditModal = props => {
	const {labelsName, onChange, unitsName} = props;
	return (
		<div>
			<div>
				<h5>Items Column Label:</h5>
				<Input value={ labelsName || "Item" } onSubmit={labelsName => onChange({labelsName})} />
			</div>
			<div>
				<h5>Units Column Label (Blank hides this column):</h5>
				<Input value={ unitsName || "Units" } onSubmit={unitsName => onChange({unitsName})} />
			</div>
		</div>
	)
}

const TitleEditModal = props => {
	const {title, onChange} = props;
	return (
		<div>
			<div>
				<h5>Title</h5>
				<Input value={ title } onSubmit={title => onChange({title})} />
			</div>
		</div>
	)
}

const SectionEditModal = props => {
	const {section, standardPrice  = 0, onChange} = props;
	return (
		<div>
			<div>
				<h5>Section Name:</h5>
				<Input value={section} onSubmit={section=> onChange({section})} />
			</div>
			<div>
				<h5>Default Item Price:</h5>
				<Input value={standardPrice} onSubmit={standardPrice=> onChange({standardPrice})} />
			</div>
		</div>
	)
}

const TotalEditModal = props => {
	const {flatDiscount, percentDiscount, onChange} = props;
	return (
		<div>
			<div>
				<h5>Percent Discount:</h5>
				<Input value={percentDiscount} onSubmit={percentDiscount=> onChange({percentDiscount})} />
			</div>
			<div>
				<h5>Flat Discount:</h5>
				<Input value={flatDiscount} onSubmit={flatDiscount=> onChange({flatDiscount})} />
			</div>
		</div>
	)
}

const NotesEditModal = props => {
	const {notes} = props;
	return(
		<div>
			<h5>Notes:</h5>
			<ExpandingText value={notes} onSubmit={notes => props.onChange({notes})} onCancel={props.closeModal} placeholder="Notes" />
		</div>
	)
}

const RowEditModal = props => {
	const {label, onChange, exchangeRate, currency, units, unitsName, price, percentDiscount, flatDiscount} = props;
	return (
		<div>
			<div>
				<h5>Label:</h5>
				<Input value={label} onSubmit={label => onChange({label})} />
			</div>
			<div>
				<h5>{unitsName} Quantity:</h5>
				<Input value={units} onSubmit={units => onChange({units})} />
			</div>
			<div>
				<h5>Price per Unit:</h5>
				<Input className="units-price" value={price} onSubmit={price => onChange({price: price/(exchangeRate || 1)})} />
			</div>
			<h5>Discounts:</h5>
			<div className="discounts-container">
				<div>
					<h5>Percent:</h5>
					<Input className="percent-discount" value={percentDiscount} onSubmit={percentDiscount=> onChange({percentDiscount})} />
				</div>
				<div>
					<h5>Flat (USD):</h5>
					<Input className="flat-discount" value={flatDiscount} onSubmit={flatDiscount=> onChange({flatDiscount})} />
				</div>
			</div>
		</div>
	)
}

class Invoice extends Component{
	constructor(props) {
		super();
		this.state={
			editingCategory: null,
			editing: {
				index: null,
				section: null,
				values: {}
			},
			editOpen: false,
			currency: props.currency, 
			exchangeRate: props.exchangeRate,
			content: props.content,
			flatDiscount: props.flatDiscount,
			percentDiscount: props.percentDiscount,
			notes: props.notes,
			businessAddress: props.businessAddress,
			clientAddress: props.clientAddress,
			tax: props.tax,
			title: props.title || "Invoice " + props._id
		}
		this.numberer = new NumberMan(0, true);
		this.numberer.places = 2;
	}

	modalContent = () => {
		const {onChange} = this;
		const {title, editing, editingCategory, exchangeRate=this.props.exchangeRate || 1, currency=this.props.currency || "$", notes = this.props.notes || '', flatDiscount = this.props.flatDiscount || 0, percentDiscount = this.props.percentDiscount || 0} = this.state;
		const {index, section, values = {}} = this.state.editing;
		if (index == Number.parseInt(index)){
			const state = this.state.content[section].content[index] || {};
			const {unitsName} = this.state.content[section];
			const {
				flatDiscount = state.flatDiscount || 0, 
				percentDiscount = state.percentDiscount || 0, 
				currency = state.currency || "$", 
				label = state.label || '', 
				units = state.units || 0, 
				price = state.price || 0
			} = values
			return <RowEditModal 
				onChange = {onChange} 
				flatDiscount={percentDiscount}
				percentDiscount={percentDiscount} 
				currency={currency}
				label={label}
				units={units}
				unitsName={unitsName}
				price={price}
				closeModal={this.closeModal}
			/>
		}
		switch(index){
			case 'section':
				const sect = this.state.content[section] || {};
				return <SectionEditModal onChange={onChange} section={section} standardPrice={sect.standardPrice || 0} />
			case 'labels':
				const category = this.state.content[section] || {};
				return <LabelsEditModal
					labelsName={category.labelsName || 'Item'}
					unitsName={category.unitsName || 'Units'}
					onChange={onChange}
				/>
			case 'title': 
				return <TitleEditModal
					title={title}
					onChange={onChange}
				/>
			case 'totals':
				return <TotalEditModal
					flat={flatDiscount}
					percent={percentDiscount}
					onChange={onChange}
				/>
			case 'notes':
				return <NotesEditModal
					notes={notes}
					onChange={onChange}
				/>
			default:
				return null
		}
	}

	closeModal = () => {
		this.timer=setTimeout(() => this.setState({editing: {index: null, section: null, values: {}}}), 800)
		this.setState({editOpen: false})
	}

	onChange = value => {
		this.setState({editing: {...this.state.editing, values: {...this.state.editing.values, ...value}}})
	}

	edit = (index, section) => {
		if(this.props.canEdit) {
			this.setState({editOpen: true, editing: {index, section}})
		}
	}

	componentDidUpdate(prevProps){
		if (prevProps !== this.props){
			const {_id, currency, exchangeRate, labelsName, content, flatDiscount, percentDiscount, notes, unitsName, businessAddress, clientAddress, tax, title} = this.props;
			this.setState({
				currency, 
				exchangeRate,
				labelsName,
				content,
				flatDiscount,
				percentDiscount,
				notes,
				unitsName,
				businessAddress,
				clientAddress,
				tax,
				title, 
			})
		}
	}

	saveEdit = () => {
		const {state} = this;
		const {index, section, values} = this.state.editing;
		switch(index){
			case 'section':
				const {standardPrice} = values;
				if (state.content[section] && section !== values.section){
					state.content[values.section] = {standardPrice, content: state.content[section].content}
					delete state.content[section]
				}
				else {
					state.content[values.section] = {standardPrice, content: []}
				}
				break;
			case 'totals':
				if (section) {
					state.content[section].percentDiscount = values.percentDiscount;
					state.content[section].flatDiscount = values.flatDiscount;
				}
				else{
					state.percentDiscount = values.percentDiscount;
					state.flatDiscount = values.flatDiscount;
				}
				break;
			case 'labels':
				for (const i in values){
					state.content[section][i] = values[i];
				}
				break;
			case 'title':
				state.title = values.title;
			case 'notes':
				state.notes = values.notes;
				break;
			default:
				state.content[section].content[index] = values
		}
		this.setState({...state, editOpen: false })
		this.fadeTimer = setTimeout(() => this.setState({editing: {index: null, section: null, values: {}}}), 800);
	}

	saveInvoice = () => {
		const {content, currency = "$", exchangeRate = 1, flatDiscount, percentDiscount, notes, businessAddress, clientAddress, tax, title} = this.state;
		const {_id} = this.props;
		if (this.props.saveInvoice){
			this.props.saveInvoice({content, currency, exchangeRate, flatDiscount, percentDiscount, notes, businessAddress, clientAddress, tax, title})
		}
	}

	render(){
		const {edit, closeModal, saveEdit} = this;
		const {saveInvoice, canEdit, _id} = this.props
		const {
			title = "Invoice " + _id,
			currency = "$", 
			exchangeRate= 1, 
			labelsName =  "Item",
			content,
			flatDiscount,
			percentDiscount,
			notes,
			unitsName,
			businessAddress,
			clientAddress,
			tax,
			editOpen,
			editing,
			editingCategory
		} = this.state;
		let subTotal = 0;
		for (const i in content) {
			const standardPrice = content[i].standardPrice;
			for (const inner in content[i].content){
				const row = content[i].content[inner]
				const {price = standardPrice, units, percentDiscount, flatDiscount} = row;
				const discount = (price * percentDiscount / 100 || 0) + (flatDiscount || 0)
				subTotal+= units * price - discount
			}
		}
		const total = subTotal - (subTotal*percentDiscount/100 || 0) - (flatDiscount || 0 )
		const taxes = tax ? tax * total / 100 : 0 
		const finalTotal = total + taxes;
		return (
			<div className="invoice-container" >
				<div className ="invoice-title" onClick={() => edit('title')}>{title}</div>
				<div className="invoice-header">
					<Address {...businessAddress} />
					{canEdit ? <Address {...clientAddress} onSubmit={clientAddress => console.log(clientAddress)} /> : <Address {...clientAddress} />}
				</div>
				<div className="invoice-body">
					{
						content ?
						Object.keys(content).map((section, i) => <InvoiceSection 
							key={"invoice-section"+i} 
							canEdit={canEdit}
							{...content[section]} 
							numberer={this.numberer} 
							name={section} 
							openEdit={index => edit(index, section)} 
						/>)
						: null
					}
					{canEdit ? <Button onClick={() => edit('section', '')}>Add Section</Button> : null}
					<table className ="invoice-totals" onClick={() => edit('totals')}>
						<tfoot>
							{flatDiscount|| percentDiscount|| tax
								? <><tr>
										<td>Subtotal:</td>
										<td>{this.numberer.toDollars(subTotal)}</td>
									</tr>
									{percentDiscount? <tr>
										<td>Percent Discount:</td>
										<td>{percentDiscount}%</td>
									</tr> : null}
									{flatDiscount? <tr>
										<td>Flat Discount:</td>
										<td>{this.numberer.toDollars(flatDiscount)}</td>
									</tr> : null}
									{tax ? <tr>
										<td>Taxes ({tax}%):</td>
										<td>{this.numberer.toDollars( taxes )}</td>
									</tr> : null}
								</>
								: null
							}
							<tr>
								<td>Total:</td>
								<td>{this.numberer.toDollars(finalTotal)}</td>
							</tr>
						</tfoot>
					</table>
				</div>
				<div className="invoice-footer">
					<div className="notes-section" onClick={() => edit('notes')}>
						<h5>Notes:</h5>
						<div className="notes-container">
							<p>{notes}</p><p>No Refunds</p>
						</div>
					</div>
				</div>
				{canEdit 
					? <div className="editing-section">
						<Button onClick={saveInvoice}>Save Invoice</Button>
						<Modal active={editOpen} closeFunction={closeModal}>
							{this.modalContent()}
							<Button onClick={() => this.saveEdit()}>Save</Button>
						</Modal>
					</div>
					: null
				}
			</div>
		)
	}
}

export default Invoice;