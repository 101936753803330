import request from 'request-promise'

export const getTutorials = (token, body) => {
	return dispatch => {
		request({
			method: 'GET',
			url: window.location.origin + '/tutorial/init',
			json: true,
			headers: {
				'x-auth': token
			}
		})
		.then(res => {
			dispatch({
				type: 'tutorials', 
				payload: body
			})
		})
		.catch( err => {
			dispatch({
				type: 'tutorialFail',
				payload: err.message
			});
		})
	}
}

export const getTutorial = (token, id) => {
	return dispatch => {
		if (token, id) {
			request({
				method: 'GET',
				json: true,
				headers: {
					'x-auth': token,
					'tuturial': id
				},
				url: window.location.origin + '/tutorial/get',
			})
			.then(res => {
				dispatch({
					type: 'tutorial',
					payload: res
				})
			})
			.catch(err => {
				dispatch({
					type: 'tutorialFail',
					payload: err
				})
			})
		}
	}
}

export const newTutorial = (token, title, description, content) => {
	return dispatch => {
		if (token && title && description && content){
			request({
				uri: window.location.origin + '/tutorial',
				method: 'POST',
				json: true,
				headers:{
					'x-auth': token
				},
				body: {
					title,
					description,
					content
				}
			})
			.then (() => {
				dispatch({
					type: 'tutorialAddSuccess'
				});
			})
			.catch(() => {
				dispatch({
					type: 'tutorialAddFail'
				})
			})
		}
	}
}