import request from 'request-promise'

const token = () => localStorage.getItem('sysv-user-token');
const url = targ => `${window.location.origin}/blog${targ ? `/${targ}` : ''}`;
const err = e => {
	return {type: 'update', payload: {blog: {error: e.message}}}
}

export const editBlog = (blogId, edit) => {
	const {title, content, description, tags} = edit;
	return dispatch => {
		request({
			method: 'POST',
			url: url('edit'),
			json: true,
			headers: {
				'x-auth': token()
			},
			body: {
				_id: blogId,
				title, 
				content, 
				description,
				tags
			}
		}).then(res => {
			dispatch({
				type: 'editSuccess',
				payload: res
			});
		}).catch(err => {
			dispatch({
				type: 'editFail',
				payload: err.message
			});
		});
	}
}

export const deleteBlog = blogId => {
	return dispatch => {
		request({
			method: 'POST',
			url: url('delete'),
			json: true,
			headers: {
				'x-auth': token()
			}
		}).then( res => {
			dispatch({type: 'blogDeleted'})
		}).catch( e => {
			dispatch(err(e))
		})
	}
}

export const newBlog = (title, content, description, tags) => {
	return dispatch => {
		request({
			method: 'POST',
			url: url(),
			json: true,
			headers: {
				'x-auth': token()
			},
			body: {
				title,
				content,
				description,
				tags
			}
		}).then( res => {
			dispatch({type: 'newSuccess'})
		}).catch( e => {
			dispatch(err(e))
		})
	}
}

export const getBlogs = () => {
	return dispatch => {
		request({
			method: 'GET',
			json: true,
			url: url('list'),
		}).then(payload => {
			dispatch({
				type: 'blogList',
				payload
			})
		}).catch( e => {
			dispatch(err(e))
		});
	}
}

export const getBlog = (query) => {
	return dispatch => {
		if (query){
			request({
				uri: url('get'),
				method: 'GET',
				json: true,
				headers:{
					'_id': query
				}
			}).then(payload => {
				dispatch({
					type: 'blog',
					payload
				})
			}).catch(e => {
				dispatch(err(e))
			})
		}
	}
}