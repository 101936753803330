export const handshake = require('./handshake.jpg');
export const bgwires = require('./bgWires.png');
export const logo = require('./logo.png');
export const miniLogo = require('./minilogo.png');
export const wires = require('./wires.jpg');
export const build = require('./buildingImage.png');
export const rocket = require('./rocket.png');
export const colin = require('./Colin.jpeg');
export const renita = require('./Renita.jpeg');
export const inspire = require('./inspire.png');
export const presenting = require('./presenting.png');
export const gamerImg = require('./wizard_attack.gif');
export const codeImg = require('./code.gif');
export const docImg = require('./documents.gif');
export const shortImg = require('./shortcut.gif');
export const stillGamer = require('./wizard_attack_still.gif');
export const stillCode = require('./code_still.gif');
export const stillDoc = require('./documents_still.gif');
export const stillShort = require('./shortcut_still.gif');
export const computer = require('./laptop.png');
export const typing = require('./typing.jpg');
export const squareLogo = require('./square-logo.png');
export const loading = require ('./loader.gif');
