import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Dropdown, filterJoin, NumberMan} from 'svz-toolkit'
import './scss/NavBar.scss'

const NavBar = props => {
	const [open, setOpen] = useState(false);
	const {logout, linksClassName, className, number, openLogin, style, elements, loggedIn, scrollToward} = props;
	const numberer = new NumberMan(number);
	if (open !== props.open && props.open === false) {
		setOpen(props.open);
		props.resetOpen();
	}
	const links = elements.map( (x, index) => {
		if (x.href){
			return <div 
				key={"svz-header-entry-"+index} 
				className={filterJoin(["nav-bar-link-container", linksClassName])} 
			>
				<Link 
					onClick={() => {scrollToward(0); setOpen(false)}} 
					to={x.href}
				>{x.children}</Link>
			</div>
		}
		else{
			return <div key={"svz-header-entry-"+index}>{x}</div>
		}
	})
	if (number){
		links.push(<div 
			key={"svz-header-entry-number"} 
			className={filterJoin(['nav-bar-link-container tel', linksClassName])} 
			onClick={() => window.open('tel:' + number)}
		> &#9990;{numberer.toPhone()} </div>)
	}
	if (!props.mobile){
		return <div className={className} style={style}>{links}</div>
	}
	else if (loggedIn && logout)
	{
		links.push(<div 
			key="svz-header-entry-profile" 
			className={filterJoin(["nav-bar-link-container", linksClassName])} 
		>
			<Link 
				onClick={() => {scrollToward(0); setOpen(false)}} 
				to="/profile">Profile</Link></div>)
		links.push(<div key="svz-header-entry-logout" className={filterJoin(["nav-bar-link-container", linksClassName])} onClick = {() => {logout(); setOpen(false)}}>Logout</div>)
	}
	else if (openLogin) {
		links.push(<div key="svz-header-entry-login" className={"nav-bar-link-container"} onClick={() => openLogin()} >Login</div>)
	}
	return <div className={filterJoin(["thin", className])} style={style}><Dropdown className={filterJoin(["mobile-dropdown", ['sub-open', props.subOpen]])} keepOpen onToggle={open => setOpen(open)} open={open} buttonClass="dropdown-button" content={links} slideIn orientation="left"><div className="dropdown-button-content" /></Dropdown></div>
}

NavBar.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	linksClassName: PropTypes.string,
	elements: PropTypes.array
};

export {NavBar};