const INITIAL_STATE = {
	error: undefined,
	blogList: [],
	_id: undefined,
	title: undefined,
	content: undefined,
	createdAt: undefined,
	edits: undefined,
	updatedAt: undefined,
	author: undefined,
}

const insertSub = (target, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = insertSub(target[i], current[i])
        }
        else {
            current[i] = target[i];
        }
    }
    if (current.filter){
        return current.filter(el => el !== undefined);
    }
    return {...current};
}

const replaceSub = (target, new_val, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = replaceSub(target[i], current[i])
        }
        else {
            current[i] = new_val;
        }

    }
    if (current.filter){
        current = current.filter(el => el !== undefined);
    }
    return {...current};
}

export default (state=INITIAL_STATE, action) => {
	const {type, payload} = action;
	switch (type) {
		case 'update':
			return replaceSub(payload.prop, payload.value, state);
		case 'overwrite':
			return insertSub(payload, state);
		case 'editSuccess':
			return {
				...state, 
				...payload, 
				editingTitle: payload.title, 
				editingContent: payload.content, 
				editingDescription: payload.description,
				editingTags: payload.tags,
				editingBlog: false
			};
		case 'editFail':
			return {...state, error: payload};
		case 'newSuccess':
			return {...state, name: undefined, contents: undefined, error: undefined, blogId: undefined};
		case 'newFail': 
			return {...state, error: payload};
		case 'blogList': 
			return {...state, blogList: payload};
		case 'blogReturnFail': 
			return {...state, error: payload};
		case 'blog':
			return {
				...state, 
				...payload, 
				editingTitle: payload.title, 
				editingContent: payload.content, 
				editingDescription: payload.description
			};
		default:
			return state;
	}
} 