import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';
import {Modal} from 'svz-toolkit';
import {BlogEdit} from './BlogEdit';

const GetBlog = props => {
	const {
		type,
		_id,
		tagList,
		editedAt,
		createdAt,
		title,
		author,
		content,
		deleteBlog,
		description,
		tags,
		onSubmit
	} = props;
	const [editing, changeEditing] = useState(false);
	const [ranSearch, changeSearch] = useState(false);
	if (!_id || _id !== window.location.search.substr(1)){
		props.getBlog(window.location.search.substr(1))
	}
	const button = type => {
		switch(type){
			case 'master': 
				button = (
					<div id = 'blog-buttons'>
						<div className = "button" onClick = { () => changeEditing(true)}>Edit</div>
						<div className = "button" onClick = {() => deleteBlog(_id)}>Delete</div>
					</div>
				)
				break;
			case 'admin':
				button = (<div className="button" onClick = {() => changeEditing(true)}>Edit</div>)
				break;
		}
	}
	const date = new Date(createdAt);
	return (
		<div id="blog-container">
			<div id="blog-header">
				<p id = "blog-title">{title}</p>
				<p id = "blog-author">{author}</p>
				<p id = "blog-date">{date.toDateString()}{editedAt ? ` (edited on ${editedAt})` : ''}</p>
			</div>
			<div id = "blog-contents">{ReactHTMLParser(content)}</div>
			{button}
			{type==='admin' || type === 'master'
				? <BlogEdit 
					onSubmit={onSubmit}
					active={editing}
					_id={_id}
					tags={tags}
					tagList={tagList}
				/>
				: null
			}
		</div>
	)
}

export default GetBlog