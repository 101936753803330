const INITIAL_STATE = {
	projects: undefined,
	email: undefined,
	error: undefined,
	messages: undefined,
	requested: undefined,
	genres: undefined,
	subjects: undefined,
	pAddressActive: undefined,
	cAddressActive: undefined,
	modals: {},
};

const insertSub = (target, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = insertSub(target[i], current[i])
        }
        else {
            current[i] = target[i];
        }
    }
    if (current.filter){
        return current.filter(el => el !== undefined);
    }
    return {...current};
}

const replaceSub = (target, newVal, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = replaceSub(target[i], newVal, current[i])
        }
        else {
            current[i] = newVal;
        }
    }
    if (current.filter){
        return current.filter(el => el !== undefined);
    }
    return {...current};
}

export default (state = INITIAL_STATE, action ) => {
	const {type, payload} = action;
	switch (type) {
        case 'update':
            return payload.site ? insertSub(payload.site, state) : {...state};
        case 'overwrite':
            return payload.prop.site ? replaceSub(payload.prop.site, payload.value, state) : {...state};
		case 'loadProjects':
			return {...state, projects: payload};
		case 'data':
			return {...state, ...payload};
		case 'sendDataFail':
			return {...state, error: payload};
		case 'getUserFail':
			return {...state, error: 'no user'};
		case 'userType':
				return {...state, type: payload}
		case 'siteReset':
			return INITIAL_STATE
		default:
			return state;
	}
}