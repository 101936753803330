import React, { Component } from 'react';
import {Link, BrowserRouter} from 'react-router-dom';
import io from 'socket.io-client';
import {connect} from 'react-redux';
import {ScreenManager, Dropdown, Parallax} from 'svz-toolkit';
import {logo, miniLogo, bgWires} from './images';
import {BottomBar, Modals} from './components';
import {Header} from './components/common';
import {MainApp} from './components/MainApp';
import popButton from './images/popButton.png';
import config from './.config.json';
import {updateValue, userSignedIn, signUserOut, update, overwrite, loginUser, createUser, getBlog, newBlog} from './actions';
import './scss/App.scss';
const loader = require('./images/loader.gif')
const construction = require('./images/construction.jpg')

const cascadeCheck = (target, element) => {
	while (target.parentElement){
		if(target===element)
			return true;
		target = target.parentElement;
	}
	return false;
}

const mapStateToProps = state => {
	const {
		auth,
		user,
		blog
	} = state;
	const {
		newUser,
		userChecked
	} = auth;
	const {
		modals,
		socket
	} = state.site;
	const {order} = state;
	return {
		newUser,
		userChecked,
		user,
		auth,
		blog
	};
}

class App extends Component {
	constructor(props) {
		super();
		this.samePage = true;
		props.addListener('resize', evt => {
			if (evt.target.innerWidth > 900 && !this.state.wide) {
				this.setState({wide: true})
				this.setState({logoActive: false});
				this.logoTimer = setTimeout(() => this.setState({logoActive: true}), 200)
			}
			else if (evt.target.innerWidth <=900 && this.state.wide) {
				this.setState({wide: false});
				this.setState({smLogoActive: false});
				this.logoTimer = setTimeout(() => this.setState({smLogoActive: true}), 200)
			}
			if (evt.target.innerWidth <= 500 && !this.state.mobile) {
				this.setState({mobile: true});
				this.setState({logoActive: false});
				this.logoTimer = setTimeout(() => this.setState({logoActive: true}), 200)
			}
			else if (evt.target.innerWidth > 500 && this.state.mobile) {
				this.setState({mobile: false})
				this.setState({logoActive: false});
				this.logoTimer = setTimeout(() => this.setState({logoActive: true}), 200)
			}
			this.setState({headerOpen: false})
		})
		props.addListener("scroll", () => {
			if (window.pageYOffset >= 50 && this.state.top) {
				this.setState({top: false})
			}
			else if (window.pageYOffset < 50 && !this.state.top) {
				this.setState({top: true})
			}
		});
		let imageLoaded = {};
		const imagesIn = {build: 3, tutor: 6, home : 5, contact: 2}
		const pages = ['build', 'tutor', 'home', 'contact'];
		for (const i of pages) {
			for (let inside = imagesIn[i]; inside > 0; inside--){
				if (imageLoaded[i]) {
					imageLoaded[i].push(false)
				} 
				else {
					imageLoaded[i] = [false]
				}
			}
		}
		this.state = {
			tutorRecommendation: {code:false, shortcuts: false, docs: false, games: false},
			clicked: {code:false, shortcuts: false, docs: false, games: false},
			show: true,
			loaded: {build: false, tutor: false, home: false, contact: false},
			imageLoaded,
			chatOpen: false,
			wide: window.innerWidth > 900,
			mobile: window.innerWidth <= 500,
			logoActive: true
		}
		this.menuTimeouts = {};
	}

	componentDidMount() {
		const socket = io.connect();
		this.props.userSignedIn(socket);
		if (window.pageYOffset >= 50 && this.state.top) {
			this.setState({top: false})
		}
		else if (window.pageYOffset < 50 && !this.state.top) {
			this.setState({top: true})
		}

	}

	openModal = (modal, active) => {
		this.props.update({modals: {[modal]: active}});
		if (modal === 'tutor' && active){
			update({order: {type: 'tutor'}})
		}
	}

	returnToTop = () => {
		this.setState({top: true})
		this.props.scrollToward(0)
	}

	toggleFunct = (index, isOpen) => {
		isOpen[index] = !isOpen[index];
		this.setState({isOpen});
	}

	closeChat = () => {
		this.setState({chatOpen: false})
	}

	renderMenus = () => {
		const linkList ={
			"Our Portfolio": {
				smallName: "Portfolio",
				content: [
					{
						title: "React",
						link: "/portfolio/react"
					},
					{
						title: "Wordpress",
						link: "/portfolio/wp"
					},
					{
						title: "Github",
						link: 'https://github.com/sysvisionz'
					},
					{
						title: "Node",
						link: 'https://www.npmjs.com/~sysvisionz'
					}
				]
			},
			"About Us": {
				smallName: "About",
				content: [
					{
						title: "Our Mission",
						link: '/about/mission'
					},
					{
						title: 'Our Team',
						link: '/about/team'
					}
				]
			},
			"Our Content": {
				smallName: "Content",
				content: [
					{
						title: "Our Blog",
						link: "/blog"
					},
					{
						title: "Learn with Us",
						link: "/tutorials"
					}
				]
			}
		}
		return Object.keys(linkList).map( key => {
			const {mobile, wide} = this.state;
			return <Dropdown 
				onToggle={open => {
					this.menuTimeouts[key] = setTimeout(() => {
						this.setState(() => {
							clearTimeout(this.menuTimeouts[key]);
							const subOpen = {...this.state.subOpen};
							subOpen[key] = open;
							return {subOpen}
						});
					}, open ? 0 : 600)
				}} 
				slideIn 
				content={linkList[key].content.map(key => 
					key.link.indexOf('http' === 0) 
					? <a href={key.link}>{key.title}</a>
					: <Link 
						onClick={() => this.setState({headerOpen: false})} 
						to={key.link}
					>
						{key.title}
					</Link>)} 
				drop={mobile ? "left" : "down"} 
				pop="down"
				orientation={mobile ? "top" : "center"}
			>
				{!wide ? linkList[key].smallName : key}
			</Dropdown> 
		})
	}


	chatOpen = () => {
		this.setState({chatOpen: true})
	}

	isClicked = (select, is) => {
		this.setState({...this.state.clicked, [select]: is});
	}

	isUp = (select, up) => {
		this.setState({tutorRecommendation: {...this.state.tutorRecommendation, [select]: up}})
	}

	render() {
		const {renderMenus, props, state, returnToTop, isUp, isClicked} = this;
		const {show, tutorRecommendation,clicked, mobile}=state;
		const {
			screenLock, 
			userChecked, 
			personal, 
			order, 
			modals, 
			updateOrder, 
			budget, 
			socket, 
			createUser, 
			loginUser, 
			blog,
			signUserOut, 
			auth,
			user,
			newUser
		} = props;
		const mainClassName =userChecked ? 'app' : 'app inactive';
		const subOpen = () => {
			if (this.state.subOpen){
				for (const i in this.state.subOpen){
					if (this.state.subOpen[i]){
						return true;
					}
				}
			}
			return false;
		}
		return user.type === 'master' ? (
			<BrowserRouter>
				<div id="app-wrapper" className={mainClassName}>
					<div className={'bgDiv'}>
						<Header 
							top = {this.state.top} 
							mobile={this.state.mobile}
							authChange = {auth => update({auth})}
							login = {loginUser}
							createUser = {createUser}
							logout = {signUserOut}
							screenLock={screenLock}
							auth = {auth}
							user = {user}
							openLogin = {() => update({auth: {activeModal: 'mobileSignIn'}})}
							loggingInContent = {<img src={loader} />}
							elements={renderMenus()}
							logo={logo}
							smallLogo={miniLogo}
							scrollToward={this.props.scrollToward}
							open={this.state.headerOpen}
							resetOpen={() => this.setState({headerOpen: null})}
							subOpen = {subOpen()}
						/>
						<MainApp 
							smoothScrolling={this.props.smoothScrolling}
							socket = {socket}
							tutorRecommendation = {tutorRecommendation}
							id="main-wrapper"
							isUp={isUp}
							clicked={clicked}
							mountFunct={() => this.setState({show:true})} 
							unmountFunct={() => this.setState({show:false})} 
							in={show}
							returnToTop={returnToTop}
							name={personal && personal.name ? personal.name : 'Stranger'}
							isClicked={isClicked}
							openModal={this.openModal}
							blog={blog}
							getBlog={getBlog}
						/>
					</div>
					<Modals 
						active={modals} 
						mobile={mobile} 
						orderSettings={order} 
						newUser={newUser} 
					/>
					<BottomBar returnToTop={returnToTop} />
				</div>
			</BrowserRouter>
		)
			: <Parallax className="construction parallax" slow smoothScrolling={this.props.smoothScrolling} src={construction}><h1>Under (re)Construction!</h1></Parallax>
	}
};

const Application = props => <ScreenManager><App {...props} /></ScreenManager>

export default connect ( mapStateToProps, {update, userSignedIn, signUserOut, overwrite, update, loginUser, createUser, getBlog, newBlog} )(Application)