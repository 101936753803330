const INITIAL_STATE = {
	categories: {},
	results: {},
	current: {},
	message: '',
	error: false
}

export default (state=INITIAL_STATE, action) => {
	const {type, payload} = action;
	switch (type) {
		case 'init': 
			return {...state, categories: payload};
		case 'edit failure':
			return {...state, error: true, message: payload};
		case 'edit success':
			return {...state, error: false, message: 'Successfully edited ' + state.current.title + '!'}
		case 'tutorial success':
			return {...state, current: payload};
		case 'tutorial search':
			return {...state, results: payload};
		default:
			return {...state};
	}
}