import React, {useState} from 'react'
import {Input, TagsPicker, Modal} from 'svz-toolkit'

export const BlogEdit = props => {
	const {active = false, content = '', description = '', title = '', tags = [], _id = null, onSubmit, tagList = []} = props;
	const [edit, changeEdit] = useState({active, content, description, title, tags})
	const editVal = value => changeEdit({...edit, ...value})
	if (active !== edit.active){
		changeEdit({active, content: content || '', description: description || '', title: title || '',  tags: tags || []})
	}
	return (
		<Modal active={props.active}>
			<form onSubmit = {props.onSubmit}>
				<div>
					<span>Tags: </span>
					<TagsPicker list={tagList} active={tags} onChange={tags => editVal({tags})} />
				</div>
				<div>
					<span>Title: </span>
					<Input id="blog-title-box" onChange = {title => editVal({title})} value={edit.title} />
				</div>
				<div>
					<span>Description: </span>
					<Input id="blog-description-box" box expanding onSubmit = {description => editVal({description})} value={edit.description} />
				</div>
				<div style={{position: "relative"}}>
					<span style={{position: "relative", top: 0}}>Content: </span>
					<Input box id="blog-content-box" onChange = {content => editVal({content})} value={edit.title} />
				</div>
				<div>
					<Input className="button" type="submit" value="Submit Blog" />
				</div>
			</form>
		</Modal>
	)
}