import { combineReducers } from 'redux';
import authReducer from './authReducer';
import blogReducer from './blogReducer';
import siteReducer from './siteReducer';
import chatReducer from './chatReducer';
import tutorialReducer from './tutorialReducer';
import orderReducer from './orderReducer';
import userReducer from './userReducer';

export default combineReducers({
	auth: authReducer,
	blog: blogReducer,
	site: siteReducer,
	chat: chatReducer,
	tutorial: tutorialReducer,
	tutor: tutorialReducer,
	order: orderReducer,
	user: userReducer
});