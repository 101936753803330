const INITIAL_STATE = {
    ready: null,
    name: '',
    business: '',
    email: '',
    address:  {
        street1: '',
        street2: '',
        city: '',
        state: 'CO',
        zip: ''
    },
    type: null,
    attentions: {
        contact: false,
        project: false,
        schedule: false,
        invoices: false
    }
}

const insertSub = (target, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = insertSub(target[i], current[i])
        }
        else {
            current[i] = target[i];
        }
    }
    if (current.filter){
        return current.filter(el => el !== undefined);
    }
    return {...current};
}

const replaceSub = (target, newVal, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = replaceSub(target[i], newVal, current[i])
        }
        else {
            current[i] = newVal;
        }
    }
    if (current.filter){
        return current.filter(el => el !== undefined);
    }
    return {...current};
}

export default (state=INITIAL_STATE, action) => {
	const {type, payload} = action;
	switch (type) {
        case 'insert':
            return payload.user ? insertSub(payload.user, state) : {...state};
        case 'overwrite':
            return payload.prop.user ? replaceSub(payload.prop.user, payload.value, state) : {...state};
        case 'init success':
        	return {...state, ...payload}
        case 'init fail':
            return {...state}
		case 'login':
            return {...state, ...payload.user}
        case 'logout':
            return {...INITIAL_STATE, ready: true}
		case 'update':
			return {...state, ...payload};
		default:
			return {...state};
	}
}