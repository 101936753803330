import request from 'request-promise';

const token = () => localStorage.getItem('sysv-user-token');
const url = targ => `${window.location.origin}/users${targ ? `/${targ}` : ''}`;
const err = e => {
	return {type: 'update', payload: {blog: {error: e.message}}}
}

export const createUser = (email, password, persist) => {
	return dispatch => {
		request({
			uri: url(),
			method: 'POST',
			body: {email, password, persist},
			json: true,
			resolveWithFullResponse: true
		})
		.then( res => dispatch({type: 'login', payload: {...res.body, token: res.headers['x-auth']}}) )
		.catch( err => dispatch( {type: 'createUserFail', payload: err.body } ) )
	}
}

export const sendUserData = (token, prop, value, prefix) => {
	const body = prefix ? {[prefix]:{[prop]: value}} : {[prop]: value};
	return dispatch => {
		request({
			method: 'POST',
			uri: url('update'),
			headers: {
				'x-auth': token()
			},
			body,
			json:true
		})
		.catch(err => dispatch({type: 'userDataFail', payload: err.message}))
	}
}

export const loginUser = (email, password, persist, socket) => {
	return dispatch => {
		request({
			method: 'POST',
			uri: url('login'),
			body: {
				email,
				password,
				persist
			},
			json: true,
			resolveWithFullResponse: true
		})
		.then( res => {
			socket.emit('signIn', {token: res.headers['x-auth']});
			dispatch({
				type: 'login', 
				payload: {
					...res.body,
					token: res.headers['x-auth']
				}
			}) 
		})
		.catch( err => {
			if (err.statusCode === 400) {
				dispatch({type: 'noUser'})
			}
			else{
				dispatch({ type: 'loginFail', payload: err.message });
			}
		});
	}
}

export const userData = (token) => {
	return dispatch => {
		request({
			method: 'GET',
			uri: url('me'),
			json: true,
			headers: {
				'x-auth': token()
			},
			resolveWithFullResponse: true
		})
		.then (res => dispatch ({type: 'userData', payload: res}))
		.catch (err => dispatch ({type: 'userDataFail', payload: err.message}))
	}
}

export const userSignedIn = socket => {
	return dispatch => {
		request({
			method: 'GET',
			uri: url('me'),
			json: true,
			headers: {
				'x-auth': token()
			},
			resolveWithFullResponse: true
		})
		.then( res => {
			socket.emit('signIn', {token, type: 'sysv'})
			dispatch({type: 'login', payload: {...res.body, token: res.headers['x-auth']}})
		})
		.catch( err => dispatch( {type: 'userNotIn'} ) )

	}
}

export const signUserOut = (token) => {
	return dispatch => {
		request({
			method: 'DELETE',
			uri: url('logout'),
			json: true,
			headers: {
				'x-auth': token
			}
		})
		.then( res => dispatch({type: 'logout'}) )
		.catch( err => dispatch({type: 'logoutFail', payload: err}) );
	}
};