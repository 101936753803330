const INITIAL_STATE = {
	budget: 3000,
	type: 'website',
	details: '',
	genres: {},
	skills: [],
	timeline: {
		start: Date.now(),
		end: Date.now()
	},
	message: {
		type: null,
		content: ''
	},
	whoFor: {
		user: true,
	}
}

const insertSub = (target, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = insertSub(target[i], current[i])
        }
        else {
            current[i] = target[i];
        }
    }
    if (current.filter){
        return current.filter(el => el !== undefined);
    }
    return {...current};
}

const replaceSub = (target, newVal, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = replaceSub(target[i], newVal, current[i])
        }
        else {
            current[i] = newVal;
        }
    }
    if (current.filter){
        return current.filter(el => el !== undefined);
    }
    return {...current};
}

export default (state=INITIAL_STATE, action) => {
	const {type, payload} = action;
	switch (type) {
        case 'update':
            return payload.order ? insertSub(payload.order, state) : {...state};
        case 'overwrite':
            return payload.prop.order ? replaceSub(payload.prop.order, payload.value, state) : {...state};
		case 'order init':
			return insertSub(payload, INITIAL_STATE);
		case 'order init fail':
			return {...state, message: {type: 'error', content: 'Startup failure: ' + payload.message}};
		case 'order submit success':
			return {...INITIAL_STATE, message: {type: 'success', content: 'Successful Order Submission!', submitting: false}};
		case 'order submit failure':
			return {...state, message: {type: 'error', content: 'Order Submission Failure: ' + payload.message, submitting: false}};
		case 'clear order':
			return {...INITIAL_STATE}
		default:
			return {...state};
	}
}