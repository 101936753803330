import React, {Component} from 'react';
import {connect} from 'react-redux';
import request from 'request-promise';
import {Dropdown} from 'svz-toolkit';
import {Link} from 'react-router-dom';
import {getTutorial, getTutorials, update} from '../actions'

const mapStateToProps = state => {
	const {
		tutorial, 
		tutorialList,
		selections
	} = state.tutorial;
	return {
		tutorial, 
		tutorialList,
		selections
	};
}

export default connect(mapStateToProps, {getTutorial, getTutorials, update}) (class Class extends Component {

	searchTutorials = (type, value) => {
		const {selections} = this.props;
		selections[type] = value;
		update(selections);
		getTutorials(selections);
	}

	selectTutorials = () => {
		const {props, searchTutorials} = this;
		const {tutorialList, foundTutorials} = this.props;
		return (
			<div id="tutorial-finder">
				<div>
					<span>Get </span><Dropdown 
						className="tutorial-dropdown" 
						onSelect={value => searchTutorials('category', value)} 
						contents={tutorialList.categories} 
						label="category" 
					/>
					<span> Tutorials on </span><Dropdown 
						className="tutorial-dropdown"
						onSelect = {value => searchTutorials('platform', value)} 
						contents={tutorialList.platforms} 
						label="platform" 
					/>
					<span> By </span><Dropdown 
						className="tutorial-dropdown"
						onSelect = {value => searchTutorials('author', value)} 
						contents={ tutorialList.authors} 
						label="author" 
					/>
				</div>
				<div id="tutorial-list">
					{foundTutorials.map(tutorial => <Link to={"/tutorials/" + tutorial.id}>{tutorial.name}</Link>)}
				</div>
			</div>
		)
	}

	componentDidMount() {
		if (this.props.search){
			const tutorial = this.props.search.substring(1);
			this.props.getTutorial(tutorial)
		}
	}

	render() {
		return (
			<div className="tutorial">
				{this.selectTutorials()}
				<div>
					<h2>{this.props.tutorial.title}</h2>
					<div id="tutorial-contents">
						{this.props.tutorial.contents}
					</div>
				</div>
				<div className="celebrationImg">

				</div>
			</div>
		)
	}
});