import request from 'request-promise'
import socketIOClient from 'socket.io-client';

export const overwrite = ( prop, value ) => {
	return {
		type: 'update', 
		payload: {prop, value}
	}
}

export const update = value => {
	return {
		type: 'overwrite',
		payload: value
	}
}

export const openModal = (index, open) => {
	if (open){
		return {type: 'open modal', payload: index}
	}
	else {
		return {type: 'close modal', payload: index}
	}
}

export const sendTutorMessage = (message, classes, currentUser) => {
	return dispatch => {

	}
}

export const checkTutorMessages = () => {
	return dispatch => {

	}
}

export const resetSite = () => {
	return {type: 'siteReset'};
}

export const sendCompanyData = ( token, prop, variable) => {
	return dispatch => {

	}
}

export const submitTutor = (skillList, user) => {
	let skills = [];
}

export const approveSkill = (skillList, uid) => {
	let skills = []
}


export const getSkillData = (currentUser) => {
	return dispatch => {
	}
}

export const getProjects = () => {

}

export const newProject = () => {

}

export const editProject = () => {
	
}

export const sendMessages = () => {

}

export const getMessageList = (user) => {
	
}