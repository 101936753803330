import React, {useState} from 'react'
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {LoginBox, Button, Attention, filterJoin} from 'svz-toolkit'
import {NavBar} from './NavBar';
import './scss/Header.scss';

const Header = props => {
	const {
		logo, 
		smallLogo, 
		top, 
		mobile, 
		user, 
		login, 
		logout, 
		authChange, 
		auth, 
		createUser, 
		loggingIn, 
		loggingInContent, 
		pickShow, 
		scrollToward, 
		screenLock,
		subOpen,
		open,
		resetOpen
	} = props;
	const [authOpen, setAuthOpen] = useState(false);
	const topName = user.email
	? user.name ? user.name : user.email.substr(0, user.email.indexOf('@'))
	: null
	return (
		<div id='header-container' className = {filterJoin([ ['auth-open',auth && auth.open], ['mobile', mobile], ['not-top', !top]])}>
			{logo ? 
				<div className="logo-container">
					<Link onClick={() => scrollToward(0)} to="/" >{
						typeof logo === "object" 
							? mobile 
								? smallLogo 
								: logo
							: <><img alt="small-logo" className="header-logo small" src={smallLogo} /><img alt="logo" className="header-logo" src={!mobile ? logo : smallLogo} /></>
						} </Link>
				</div>
				: null
			}
			<NavBar 
				elements={props.elements}
				scrollToward={scrollToward}
				className='nav-bar'
				linksClassName="nav-bar-link"
				openLogin={authOpen ? () => authOpen(true) : null}
				loggedIn = {user && !!user.email}
				pickShow = {pickShow}
				subOpen = {subOpen}
				open={open}
				resetOpen={resetOpen}
				mobile={mobile}
			/>
			{
				login 
				? !user.email ? 
					<LoginBox 
						screenLock = {screenLock}
						{...auth}
						login={login} 
						onChange={authChange}
						createUser={createUser}
						loggingIn={loggingIn}
						loggingInContent={loggingInContent}
						ready={user.ready}
					/>
					: <div className={filterJoin(["greeting-box",['ready', user.ready]])}>
						<span>Hello there, <Link  onClick={() => scrollToward(0)} to = "/profile">{topName}<Attention on={props.auth.attentions && props.auth.attentions.includes(true)}/></Link></span>
						<Button className="thin" onClick = {() => logout()}>Log Out</Button>
					</div>
				: null
			}
		</div>
	)
}

Header.propTypes = {
	top: PropTypes.bool,
	mobile: PropTypes.bool,
	user: PropTypes.object,
	password: PropTypes.string,
	logIn: PropTypes.func,
	createUser: PropTypes.func
};

export {Header};