import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Modal} from 'svz-toolkit';
import Fader from './Fader'; 
import {BlogEdit} from './BlogEdit'

const Blog = props => {
	const {
		getBlog,
		blogList,
		onSubmit,
		tags,
		tagList
	} = props;
	const [createOpen, openCreate] = useState(false)
	return (
		<Fader key={props.key}>
			<div>
				<div>
					{props.blogList[0]
						? props.blogList.map(blog => {
							return (
								<div className="blogEntry">
									<div>
										<Link to={"/blog/ref?"+encodeURI(blog._id)}>{blog.title}</Link>
									</div>
									<div>
										<span>{blog.description}</span>
									</div>
								</div>
							)
						})
						: <span>{props.error}</span>
					}
					{props.type === 'admin' || props.type === 'master'
						? <button onClick={() => openCreate(true)}>New Blog Entry</button> 
						: null
					}
				</div>
				<BlogEdit 
					onSubmit={onSubmit}
					active={createOpen}
					tags={tags}
					tagList={tagList}
				/>
			</div>
		</Fader>
	)
}

export default Blog