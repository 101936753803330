const INITIAL_STATE = {
	loggedIn: false,
	email: '',
	password: '',
	promoteOpen: false,
	authOpen: false,
	authMenuOpen: false,
	isVisible: true,
	company: undefined,
	contractSigned: undefined,
	website: undefined,
	error: false,
	newUser: false,
	loggingIn: false,
	userChecked: false,
	misc: undefined,
	persist: true,
	attentions: {
		projects: false,
		schedule: false,
		chat: false,
		orders: false
	},
	currentEdit: undefined
}
const insertSub = (target, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = insertSub(target[i], current[i])
        }
        else {
            current[i] = target[i];
        }
    }
    if (current.filter){
        return current.filter(el => el !== undefined);
    }
    return {...current};
}

const replaceSub = (target, newVal, current) => {
    for (const i in target){
        if (typeof target[i] === 'object' && current[i]){
            current[i] = replaceSub(target[i], newVal, current[i])
        }
        else {
            current[i] = newVal;
        }
    }
    if (current.filter){
        return current.filter(el => el !== undefined);
    }
    return {...current};
}

export default (state = INITIAL_STATE, action ) => {
	const {type, payload} = action;
	switch (type) {
        case 'update':
            return payload.auth ? insertSub(payload.auth, state) : {...state};
        case 'overwrite':
            return payload.prop.auth ? replaceSub(payload.prop.auth, payload.value, state) : {...state};
		case 'promoteUserFail': 
			return {...state, error: payload }
		case 'userData': 
			return {...state, ...payload, loggedIn: true}
		case 'userDataFail':
			return {...state, error: payload}
		case 'promoteUserSuccess':
			return {...state, misc: `User ${payload} successfully promoted`}
		case 'login':
			return {...state, ...payload, loggingIn: false, userChecked: true, newUser: false, loggedIn: true};
		case 'logout':
			return {...INITIAL_STATE, userChecked: true, loggingIn: false, loggedIn: false};
		case 'updateValue':
			return {...state, [payload.prop]: payload.value};
		case 'noUser':
			return {...state, newUser: true};
		case 'loginFail':
			return {...state, error: 'Invalid username or password', loggingIn: false};
		case 'createUserFail':
			return {...state, error: payload}
		case 'closeModal':
			return {...state, newUser: false, loggingIn: false};
		case 'userNotIn':
			return {...state, userChecked: true};
		case 'loggingIn':
			return {...state, loggingIn: true, error: false};
		default:
			return {...state};
	}
}