import React from 'react'
import PropTypes from 'prop-types';
import {Modal, ContactForm} from 'svz-toolkit';

const Modals = props => {
	const {tutor, order, mustSignIn, newUser, signIn} = props;
	return (
		<div>
			<Modal active={order}>
				<ContactForm /> 
			</Modal>
		</div>
	)
}

Modals.propTypes = {

};

export {Modals};