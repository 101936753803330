import React, {Component} from 'react'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Parallax} from 'svz-toolkit';
import {DetailBox, Modal} from './common';
import Fader from './Fader';
import {handshake, wires, typing} from '../images';

const Home = props => {
	return (
		<Fader>
			<div>
				<div id="home-floating" className='titleText'>
					<div className="over-background">
						<div>
							<h2>
								Our Systems<br />
								<span>Your Vision</span>
							</h2>
							<button id="share-vision" onClick={() => props.openModal(props.user ? 'homeOrderModal' : 'mustSignIn' ,true)}>
								Build your Vision with us
							</button>
						</div>
					</div>
					<img alt="shaking-hands" className = "full-img" src={handshake} />
				</div>
				<div id="homeWrapper" >
					<Parallax 
						className="dark-section" 
						src={wires}
						slow
						offsetY={200}
						smoothScrolling={props.smoothScrolling}
					>
						<h1>
							What's your Vision?
						</h1>
						<p>
							You have something you want built. Maybe it's a website to get your message out.
						</p>
						<p>
							Maybe it's an app to spread your message, or get new clients.
						</p>
						<p>
							Maybe you just want to find out how technology can be used to leverage your business.
						</p>
					</Parallax>
					<div> 
						<p>
							This is what we do.
						</p>
						<p>
							We take your visions of the future and help you breathe life into them.
						</p>
					</div>
					<div className = "content-section">
						<p>
							We put in everything you need, and nothing that you don't, making for an experience and a product you won't find anywhere else.
						</p>
						
						<p>
							This all starts with our proven three step process:
						</p>
						<ol>
							<li>Consultation</li>
							<li>Assessment</li>
							<li>Execution</li>
						</ol>
					</div>
				</div>
			</div>
		</Fader>
	)
}

Home.propTypes = {

}

export {Home};