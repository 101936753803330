import React from 'react'
import Fader from './Fader';

export const Details = () => {
	return (
		<Fader>
			<div>
				Details
			</div>
		</Fader>
	)
}